import { default as handler3YPJAvBPRfMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/__/auth/handler.vue?macro=true";
import { default as _91_46_46_46seotag_93TL8yLuVF14Meta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/[...seotag].vue?macro=true";
import { default as access_45datacSgT9HEVN9Meta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/account/access-data.vue?macro=true";
import { default as indexLeEJJrfpXGMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/account/addresses/index.vue?macro=true";
import { default as couponsYQMbfFJUpbMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/account/coupons.vue?macro=true";
import { default as indexr5X7BA5naqMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/account/creditcards/index.vue?macro=true";
import { default as favouritesOLMTnQ4BiGMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/account/favourites.vue?macro=true";
import { default as indexZncoE6Uxf7Meta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/account/index.vue?macro=true";
import { default as loyalty_45pointscvCGBQgvDlMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/account/loyalty-points.vue?macro=true";
import { default as notificationsPclZUPNC0RMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/account/notifications.vue?macro=true";
import { default as index9Gdqel7oLrMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/account/orders/[id]/index.vue?macro=true";
import { default as index6N0crBezSOMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/account/orders/index.vue?macro=true";
import { default as personal_45dataeJgSHOKBYaMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/account/personal-data.vue?macro=true";
import { default as preferencesCwPRm9cLesMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/account/preferences.vue?macro=true";
import { default as add1erKmlsJmhMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/account/reviews/add.vue?macro=true";
import { default as stock_45alertssdwgypSys1Meta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/account/stock-alerts.vue?macro=true";
import { default as newGd2Msyt5qWMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/app/webview/creditcards/new.vue?macro=true";
import { default as braintreevZFDx5AptLMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/app/webview/payment/gateway/braintree.vue?macro=true";
import { default as klarnabIrRidkAudMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/app/webview/payment/gateway/klarna.vue?macro=true";
import { default as multisafepay1cEYorkb53Meta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/app/webview/payment/gateway/multisafepay.vue?macro=true";
import { default as _91sessionid_93R9P6RZtTbuMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/app/webview/payment/gateway/paycomet/[result]/[sessionid].vue?macro=true";
import { default as koUftxiVdMaQMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/app/webview/payment/result/ko.vue?macro=true";
import { default as okGKBdBwlpT6Meta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/app/webview/payment/result/ok.vue?macro=true";
import { default as challengeY2GedOXmjFMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/app/webview/payment/test/challenge.vue?macro=true";
import { default as _91id_93R6gkxxTGL0Meta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/app/webview/product/nutritionfacts/[id].vue?macro=true";
import { default as recoveryWRKYPhI5tEMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/cart/recovery.vue?macro=true";
import { default as index34l5H5DUAGMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/checkout/[id]/index.vue?macro=true";
import { default as thanku0LyU5YHKkGMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/checkout/[id]/thanku.vue?macro=true";
import { default as indexmuwHEbidmSMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/conocenos/index.vue?macro=true";
import { default as indexnlCfTqfgxZMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/index.vue?macro=true";
import { default as cookie_45policytCbh50Q59QMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/legal/cookie-policy.vue?macro=true";
import { default as loginPYR2Aw11rcMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/login.vue?macro=true";
import { default as indexOjpo3wCetrMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/marcas/index.vue?macro=true";
import { default as _91_46_46_46seotag_93vuelkCrnWVMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/promo/[...seotag].vue?macro=true";
import { default as _91result_93fHvcn6WXzdMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/public/checkout/payment/gateway/paycomet/[result].vue?macro=true";
import { default as _91session_93bx6RLqDEWxMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/public/checkout/payment/gateway/redsys/[session].vue?macro=true";
import { default as _91result_93I5iDLKtwVkMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/public/checkout/payment/gateway/redsys/result/[result].vue?macro=true";
import { default as recoveryQ4B8smlYlmMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/recovery.vue?macro=true";
import { default as indexDbm7w41w5cMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/tiendas/[seotag]/index.vue?macro=true";
import { default as indexkBSzEiIdolMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/tiendas/index.vue?macro=true";
import { default as _91_46_46_46slug_93ZkfWk5JJTcMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/storyblok/draft/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93Qc3q4LG4TLMeta } from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/storyblok/published/[...slug].vue?macro=true";
export default [
  {
    name: "__-auth-handler",
    path: "/__/auth/handler",
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/__/auth/handler.vue").then(m => m.default || m)
  },
  {
    name: "locale-seotag",
    path: "/:locale()/:seotag(.*)*",
    meta: _91_46_46_46seotag_93TL8yLuVF14Meta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/[...seotag].vue").then(m => m.default || m)
  },
  {
    name: "locale-account-access-data",
    path: "/:locale()/account/access-data",
    meta: access_45datacSgT9HEVN9Meta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/account/access-data.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-addresses",
    path: "/:locale()/account/addresses",
    meta: indexLeEJJrfpXGMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-coupons",
    path: "/:locale()/account/coupons",
    meta: couponsYQMbfFJUpbMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/account/coupons.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-creditcards",
    path: "/:locale()/account/creditcards",
    meta: indexr5X7BA5naqMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/account/creditcards/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-favourites",
    path: "/:locale()/account/favourites",
    meta: favouritesOLMTnQ4BiGMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/account/favourites.vue").then(m => m.default || m)
  },
  {
    name: "locale-account",
    path: "/:locale()/account",
    meta: indexZncoE6Uxf7Meta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/account/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-loyalty-points",
    path: "/:locale()/account/loyalty-points",
    meta: loyalty_45pointscvCGBQgvDlMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/account/loyalty-points.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-notifications",
    path: "/:locale()/account/notifications",
    meta: notificationsPclZUPNC0RMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/account/notifications.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-orders-id",
    path: "/:locale()/account/orders/:id()",
    meta: index9Gdqel7oLrMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/account/orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-orders",
    path: "/:locale()/account/orders",
    meta: index6N0crBezSOMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-personal-data",
    path: "/:locale()/account/personal-data",
    meta: personal_45dataeJgSHOKBYaMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/account/personal-data.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-preferences",
    path: "/:locale()/account/preferences",
    meta: preferencesCwPRm9cLesMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/account/preferences.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-reviews-add",
    path: "/:locale()/account/reviews/add",
    meta: add1erKmlsJmhMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/account/reviews/add.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-stock-alerts",
    path: "/:locale()/account/stock-alerts",
    meta: stock_45alertssdwgypSys1Meta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/account/stock-alerts.vue").then(m => m.default || m)
  },
  {
    name: "locale-app-webview-creditcards-new",
    path: "/:locale()/app/webview/creditcards/new",
    meta: newGd2Msyt5qWMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/app/webview/creditcards/new.vue").then(m => m.default || m)
  },
  {
    name: "locale-app-webview-payment-gateway-braintree",
    path: "/:locale()/app/webview/payment/gateway/braintree",
    meta: braintreevZFDx5AptLMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/app/webview/payment/gateway/braintree.vue").then(m => m.default || m)
  },
  {
    name: "locale-app-webview-payment-gateway-klarna",
    path: "/:locale()/app/webview/payment/gateway/klarna",
    meta: klarnabIrRidkAudMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/app/webview/payment/gateway/klarna.vue").then(m => m.default || m)
  },
  {
    name: "locale-app-webview-payment-gateway-multisafepay",
    path: "/:locale()/app/webview/payment/gateway/multisafepay",
    meta: multisafepay1cEYorkb53Meta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/app/webview/payment/gateway/multisafepay.vue").then(m => m.default || m)
  },
  {
    name: "locale-app-webview-payment-gateway-paycomet-result-sessionid",
    path: "/:locale()/app/webview/payment/gateway/paycomet/:result()/:sessionid()",
    meta: _91sessionid_93R9P6RZtTbuMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/app/webview/payment/gateway/paycomet/[result]/[sessionid].vue").then(m => m.default || m)
  },
  {
    name: "locale-app-webview-payment-result-ko",
    path: "/:locale()/app/webview/payment/result/ko",
    meta: koUftxiVdMaQMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/app/webview/payment/result/ko.vue").then(m => m.default || m)
  },
  {
    name: "locale-app-webview-payment-result-ok",
    path: "/:locale()/app/webview/payment/result/ok",
    meta: okGKBdBwlpT6Meta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/app/webview/payment/result/ok.vue").then(m => m.default || m)
  },
  {
    name: "locale-app-webview-payment-test-challenge",
    path: "/:locale()/app/webview/payment/test/challenge",
    meta: challengeY2GedOXmjFMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/app/webview/payment/test/challenge.vue").then(m => m.default || m)
  },
  {
    name: "locale-app-webview-product-nutritionfacts-id",
    path: "/:locale()/app/webview/product/nutritionfacts/:id()",
    meta: _91id_93R6gkxxTGL0Meta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/app/webview/product/nutritionfacts/[id].vue").then(m => m.default || m)
  },
  {
    name: "locale-cart-recovery",
    path: "/:locale()/cart/recovery",
    meta: recoveryWRKYPhI5tEMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/cart/recovery.vue").then(m => m.default || m)
  },
  {
    name: "locale-checkout-id",
    path: "/:locale()/checkout/:id()",
    meta: index34l5H5DUAGMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/checkout/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-checkout-id-thanku",
    path: "/:locale()/checkout/:id()/thanku",
    meta: thanku0LyU5YHKkGMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/checkout/[id]/thanku.vue").then(m => m.default || m)
  },
  {
    name: "locale-conocenos",
    path: "/:locale()/conocenos",
    meta: indexmuwHEbidmSMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/conocenos/index.vue").then(m => m.default || m)
  },
  {
    name: "locale",
    path: "/:locale()",
    meta: indexnlCfTqfgxZMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-legal-cookie-policy",
    path: "/:locale()/legal/cookie-policy",
    meta: cookie_45policytCbh50Q59QMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: "locale-login",
    path: "/:locale()/login",
    meta: loginPYR2Aw11rcMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/login.vue").then(m => m.default || m)
  },
  {
    name: "locale-marcas",
    path: "/:locale()/marcas",
    meta: indexOjpo3wCetrMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/marcas/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-promo-seotag",
    path: "/:locale()/promo/:seotag(.*)*",
    meta: _91_46_46_46seotag_93vuelkCrnWVMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/promo/[...seotag].vue").then(m => m.default || m)
  },
  {
    name: "locale-public-checkout-payment-gateway-paycomet-result",
    path: "/:locale()/public/checkout/payment/gateway/paycomet/:result()",
    meta: _91result_93fHvcn6WXzdMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/public/checkout/payment/gateway/paycomet/[result].vue").then(m => m.default || m)
  },
  {
    name: "locale-public-checkout-payment-gateway-redsys-session",
    path: "/:locale()/public/checkout/payment/gateway/redsys/:session()",
    meta: _91session_93bx6RLqDEWxMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/public/checkout/payment/gateway/redsys/[session].vue").then(m => m.default || m)
  },
  {
    name: "locale-public-checkout-payment-gateway-redsys-result-result",
    path: "/:locale()/public/checkout/payment/gateway/redsys/result/:result()",
    meta: _91result_93I5iDLKtwVkMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/public/checkout/payment/gateway/redsys/result/[result].vue").then(m => m.default || m)
  },
  {
    name: "locale-recovery",
    path: "/:locale()/recovery",
    meta: recoveryQ4B8smlYlmMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/recovery.vue").then(m => m.default || m)
  },
  {
    name: "locale-tiendas-seotag",
    path: "/:locale()/tiendas/:seotag()",
    meta: indexDbm7w41w5cMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/tiendas/[seotag]/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-tiendas",
    path: "/:locale()/tiendas",
    meta: indexkBSzEiIdolMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/[locale]/tiendas/index.vue").then(m => m.default || m)
  },
  {
    name: "storyblok-draft-slug",
    path: "/storyblok/draft/:slug(.*)*",
    meta: _91_46_46_46slug_93ZkfWk5JJTcMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/storyblok/draft/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "storyblok-published-slug",
    path: "/storyblok/published/:slug(.*)*",
    meta: _91_46_46_46slug_93Qc3q4LG4TLMeta || {},
    component: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/pages/storyblok/published/[...slug].vue").then(m => m.default || m)
  }
]